<script setup lang="ts">
import BankingProviderCard from '@/app/components/onboarding/BankingProviderCard.vue';
import CurrencyCloudIcon from '@/app/icons/svgs/CurrencyCloudIcon.vue';
import NatWestIcon from '@/app/icons/svgs/NatWestIcon.vue';
import { useAuthStore } from '@/app/store/authStore';
import { VRow } from 'ah-common-lib/src/common/components';
import { ref } from 'vue';

const bankingProviderData = [
  {
    name: 'NatWest',
    icon: { component: NatWestIcon, width: '22px' },
    advantages: [
      'Your account will be powered by one of the UK’s leading banks, regulated by the PRA in adherence with the highest regulatory standards to ensure a secure and compliant environment for pooled accounts.',
      'NatWest provides ALT21 with local payment rails in GBP, CAD, and USD, leading to more efficient and cost beneficial payments.',
      'Unlike traditional FX brokers, ALT21 automates the entire process from start to finish, even with our pooled accounts.',
      'NatWest handles one in four daily payments in the UK and ranks among the top 25% of SWIFT processors globally, ensuring extensive worldwide coverage.',
    ],
    disadvantages: ['Virtual IBANs are currently unavailable with NatWest.'],
  },
  {
    name: 'CurrencyCloud',
    icon: { component: CurrencyCloudIcon, width: '30px' },
    advantages: [
      'CurrencyCloud is regulated by the FCA and adheres to strict security protocols specifically designed for cross-border payments.',
      'Benefit from individual Virtual IBANs for each of your multi-currency wallets, in your own company’s name.',
    ],
    disadvantages: [
      'Some banks disfavour VIBANs, which can delay payment efficacy.',
      'Multi-currency IBANs use the "GB" country code, which can lead to difficulties with local correspondent banks.',
      'As CurrencyCloud is a BaaS provider rather than the correspondent bank, the third layer of transaction screening layer may sometimes cause payment delays.',
    ],
  },
];

const selectedProvider = ref<string | null>(null);

const authStore = useAuthStore();

const clientName = authStore.loggedInIdentity!.firstName;

const emit = defineEmits<{
  (e: 'proceed', value: void): void;
}>();

const selected = (selectedValue: string) => {
  selectedProvider.value = selectedValue;
};

const finishOnboardingRequest = () => {
  // TODO: Placeholder for "Continue" request to finish onboarding
  emit('proceed');
};
</script>

<template>
  <VRow class="main mb-4">
    <div class="mainText">
      <h1>You are almost done, {{ clientName }}</h1>
      <p class="subtitle">
        To complete your account setup, please choose a banking provider from the options below. This cannot be changed,
        so please read carefully.
      </p>
    </div>
    <div class="cardsRow">
      <div class="providersCards" v-for="(provider, index) in bankingProviderData" :key="index">
        <BankingProviderCard
          @selected="selected"
          :title="provider.name"
          :advantages="provider.advantages"
          :disadvantages="provider.disadvantages"
          :selected="provider.name === selectedProvider"
          :icon="provider.icon"
        >
          <template #banking-icon>
            <component class="mr-1" :is="provider.icon.component" :style="`width:${provider.icon.width}`" />
          </template>
        </BankingProviderCard>
      </div>
    </div>
    <VButton class="btn btn-primary" @click="() => finishOnboardingRequest()" :disabled="selectedProvider === null">
      Continue
    </VButton>
  </VRow>
</template>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

h1 {
  @include themedTextColor($common-color-widget-cyan, $common-color-light-cyan);
  font-size: 18px;
  font-weight: $font-weight-medium;
}

.subtitle {
  @include themedTextColor($xo-color-dark-gray, $common-color-white);
  font-size: 14px;
}

.providersCards {
  &:not(:last-child) {
    margin-right: 100px;
  }
}

.cardsRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 88px;
}

.mainText {
  width: 678px;
  margin-right: 26rem;
  margin-bottom: 3rem;
}
</style>
