<template>
  <div class="email-change">
    <div class="field-title mb-1">Email</div>
    <div class="email">{{ currentEmail }}</div>
    <div class="link">
      <a class="text-secondary change-link text-small" @click="openChangeModal">Change email</a>
    </div>
    <div class="text-small pending-email" v-if="newEmail">
      <div class="icon">
        <IconDangerSign />
      </div>
      <div class="text">You have not verified your new email address<br />{{ newEmail }}.</div>
      <div class="text-secondary">Please check your spam or junk folder</div>
      <div class="link">
        <a class="text-secondary change-link" @click="resendEmail">
          {{ requestManager.requestStates.resendEmail === 'pending' ? 'Sending...' : 'Resend verification email' }}
        </a>
      </div>
    </div>
    <BModal
      modal-class="change-modal"
      title="Change account email"
      ref="changeModal"
      hide-footer
      @hidden="onChangeModalHidden"
    >
      <div v-if="state === 'prechange'">
        <div class="mb-3">
          <div class="field-title mb-1">Email</div>
          <div class="email">{{ currentEmail }}</div>
        </div>
        <ValidatedForm :fm="changeContactsForm" :validation.sync="emailValidation" class="mb-3 phone-form" />
        <OtpRequestForm
          ref="changeOtpRequest"
          class="mb-5"
          title="For security reasons, before confirming your new
        email we must verify your request."
          :otp.sync="otp"
          :staticOTP="staticOTP"
          :otpPhone="currentPhoneLastDigits"
          :refreshOtpRequest="refreshOtpRequest"
          :validation.sync="otpValidation"
          :forbidOtpRequest="isNewEmailInvalid"
          v-if="tfaEnabled"
        />
        <p class="text-center">
          <VButton @click="closeChangeModal" class="btn-stroked mr-3">Cancel</VButton>
          <VButton :loading="requestManager.anyPending" :disabled="anyInvalid" @click="changeEmail">Confirm</VButton>
        </p>
      </div>
      <div v-else class="post-change">
        <p class="text-center">
          <IconMail class="mail-icon" />
        </p>
        <h2 class="text-center">Please confirm your email address</h2>
        <p class="text-center">
          We sent a verification to<br />
          <span class="purple">
            {{ newEmail }}
          </span>
        </p>
        <p class="text-center">Please click the verification link in the email to confirm your new email address.</p>
      </div>
    </BModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import OtpRequestForm from 'ah-common-lib/src/otp/OtpRequestForm.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { emailField } from 'ah-common-lib/src/form/models';
import config from '@/config';
import { SecurityErrorCodes } from 'ah-api-gateways';
import { mergeMap } from 'rxjs/operators';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { helpers, email } from '@vuelidate/validators';
import { requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { FormModel } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { Observable } from 'rxjs';

/**
 * Phone number change component
 * This component will:
 * - Display the current telephone number, as defined in the logged in user
 * - Show a warning if the user has changed but not validated a new phone number
 */
@Component({
  components: {
    OtpRequestForm,
  },
})
export default class AccountEmailChange extends Mixins(WithRequestManager) {
  $refs!: {
    changeModal: BModal;
    changeOtpRequest: any; // InstanceType<typeof OtpRequestForm>;
    validateOtpRequest: any; // InstanceType<typeof OtpRequestForm>;
  };

  private state: 'prechange' | 'postchange' = 'prechange';

  private otp = '';

  emailValidation: FormValidation | null = null;

  otpValidation: FormValidation | null = null;

  requestManagerConfig = {
    exposeToParent: false,
  };

  private changeContactsForm: FormModel | null = null;

  created() {
    this.changeContactsForm = makeFormModel({
      name: 'changeEmailForm',
      fieldType: 'form',
      fields: [
        emailField(
          'email',
          'New email',
          {
            required: true,
            errorMessages: { differentFrom: 'Cannot be the same as the current email' },
          },
          {
            differentFrom: helpers.withParams({ type: 'differentFrom' }, (val: any) => val !== this.currentEmail),
            email,
            required: requiredIfStateValue('email'),
          }
        ),
      ],
    });
  }

  get authStore() {
    return useAuthStore();
  }

  get anyInvalid() {
    if (!this.otpValidation || !this.emailValidation) {
      return true;
    }

    return this.otpValidation?.$invalid !== false || this.emailValidation?.$invalid !== false;
  }

  get isNewEmailInvalid() {
    if (!this.emailValidation) {
      return true;
    }

    return this.emailValidation?.$invalid !== false;
  }

  get currentEmail() {
    return this.authStore.loggedInUser?.email ?? '';
  }

  get currentPhoneNumber() {
    return this.authStore.loggedInUser?.phoneNumber ?? '';
  }

  get currentPhoneLastDigits() {
    return this.currentPhoneNumber.substring(Math.max(0, this.currentPhoneNumber.length - 3));
  }

  get newEmail() {
    return this.authStore.loggedInUser?.secondaryEmail ?? '';
  }

  get staticOTP() {
    return config.staticOTP;
  }

  get tfaEnabled() {
    return !!this.authStore.userData?.tfaEnabled;
  }

  changeEmail() {
    let request: Observable<void> = this.tfaEnabled
      ? this.$services.account.changeContacts(
          {
            email: this.changeContactsForm!.email,
          },
          this.otp,
          {
            errors: {
              silent: true,
            },
          }
        )
      : this.$services.account.changeContacts(
          {
            email: this.changeContactsForm!.email,
          },
          undefined,
          {
            errors: {
              silent: true,
            },
          }
        );

    this.requestManager
      .sameOrCancelAndNew('changeEmail', request.pipe(mergeMap(() => this.$services.auth.getSession())))
      .subscribe(
        (session) => {
          this.authStore.setTokenUser(session);
          if (session.secondaryEmail) {
            this.state = 'postchange';
          } else {
            if (this.changeContactsForm!.email === session.email) {
              this.$toast.success('Email changed successfully');
            } else {
              this.$toast.error('Email change failed. Please try again later.');
            }
            this.closeChangeModal();
          }
        },
        (e) => {
          if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
            this.$refs.changeOtpRequest?.setOtpError(
              e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
            );
          } else if (e.response?.status === 409 || e.response?.status === 400) {
            this.$toast.info(e.response.data.message);
          } else {
            this.$toast.error('An unexpected problem has occurred. Please try again later.');
          }
        }
      );
  }

  resendEmail() {
    this.requestManager
      .currentOrNew(
        'resendEmail',
        this.$services.account.resendEmailForChange({
          errors: {
            silent: true,
          },
        })
      )
      .subscribe(
        () => {
          this.$toast.success('Resent verification email');
        },
        () => {
          this.$toast.error('Verification email failed to send');
        }
      );
  }

  refreshOtpRequest() {
    return this.$services.auth.refreshOtp();
  }

  refreshNewPhoneOtpRequest() {
    return this.$services.account.refreshOtpForNumberChange();
  }

  closeChangeModal() {
    this.$refs.changeModal.hide();
  }

  onChangeModalHidden() {
    this.otpValidation = null;
  }

  openChangeModal() {
    this.state = 'prechange';
    this.otp = '';
    this.$refs.changeModal.show();
  }
}
</script>

<style lang="scss" scoped>
.email {
  word-wrap: break-word;
}

.change-link {
  text-decoration: underline;
}

.pending-email {
  margin-top: 0.3em;
  position: relative;

  .text,
  .icon {
    color: getColor($color-danger);
  }

  .text,
  .text-secondary,
  .change-link {
    padding-left: 2em;
  }

  .icon {
    position: absolute;
    left: 0;
    top: -0.15em;
  }
}

.post-change {
  .mail-icon {
    font-size: 4em;
  }
}
</style>
