import { ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { HttpError } from 'ah-requests';
import { AuthErrorCodes } from 'ah-api-gateways';
import { FormModel } from 'ah-common-lib/src/form/interfaces';
import { setApiErrorMessages, setErrorMessage } from 'ah-common-lib/src/form/helpers';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

interface UsernameValidationState {
  isValidating: boolean;
  isValid: boolean;
  hasError?: boolean;
  wasValidated?: boolean;
}

export function useUsernameValidation(accountAccessForm: FormModel) {
  const { manager: requestManager } = useRequestManager();
  const services = getServices();
  const isUsernameGenerated = ref(false);

  const validationState = ref<UsernameValidationState>({
    isValidating: false,
    isValid: false,
    wasValidated: false,
  });

  const validateUsernamePromise = (username: string, showConflictError: boolean = false): Promise<boolean> => {
    return requestManager.newPromise(
      'validateUsername',
      services.registration
        .usernameValidation({ username }, { errors: { silent: true } })
        .toPromise()
        .then(
          () => true,
          (error: HttpError) => {
            const apiErrors = constructPayloadErrors(error.response!.data);

            if (error?.response?.data?.code === AuthErrorCodes.CLIENT_USERNAME_CONFLICT) {
              if (showConflictError) {
                setErrorMessage(accountAccessForm, 'username', error.response.data.message);
              }
              return false;
            }

            setApiErrorMessages(apiErrors, accountAccessForm);
            validationState.value = {
              isValidating: false,
              isValid: false,
              hasError: true,
              wasValidated: true,
            };
            return false;
          }
        )
    );
  };

  const generateUniqueUsername = async (baseUsername: string): Promise<string | undefined> => {
    const isAvailable = await validateUsernamePromise(baseUsername);

    if (validationState.value.hasError || isAvailable) {
      return isAvailable ? baseUsername : undefined;
    }

    const usedNumbers = new Set<number>();
    const MAX_API_ATTEMPTS = 5;

    while (usedNumbers.size < MAX_API_ATTEMPTS) {
      const randomNum = Math.floor(Math.random() * 1000);
      if (usedNumbers.has(randomNum)) continue;

      usedNumbers.add(randomNum);
      const newUsername = `${baseUsername}${randomNum}`;
      const isNewAvailable = await validateUsernamePromise(newUsername);

      if (validationState.value.hasError || isNewAvailable) {
        return isNewAvailable ? newUsername : undefined;
      }
    }

    return undefined;
  };

  const handleUsernameGeneration = async (firstName: string, lastName: string, generatedUsername: string) => {
    if (firstName && lastName && generatedUsername) {
      validationState.value.isValidating = true;
      const uniqueUsername = await generateUniqueUsername(generatedUsername);

      if (uniqueUsername) {
        accountAccessForm.username = uniqueUsername;
        isUsernameGenerated.value = true;
        validationState.value = {
          isValidating: false,
          isValid: true,
          hasError: false,
          wasValidated: true,
        };
      } else {
        accountAccessForm.username = generatedUsername;
        isUsernameGenerated.value = false;
        validationState.value = {
          isValidating: false,
          isValid: false,
          hasError: true,
          wasValidated: true,
        };
      }
    }
  };

  const handleUsernameValidation = async (value: string) => {
    if (!value || validationState.value.isValidating) return;

    validationState.value.isValidating = true;
    const isAvailable = await validateUsernamePromise(value, true);

    validationState.value = {
      isValidating: false,
      isValid: isAvailable,
      hasError: !isAvailable,
      wasValidated: true,
    };
  };

  const handleUsernameChange = () => {
    isUsernameGenerated.value = false;
    validationState.value = {
      isValidating: false,
      isValid: false,
      wasValidated: false,
    } as UsernameValidationState;
  };

  return {
    usernameValidationState: validationState,
    isUsernameGenerated,
    handleUsernameGeneration,
    handleUsernameValidation,
    handleUsernameChange,
  };
}
