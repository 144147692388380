import { isArray } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { BaseFormModel, FormModel, RepeatModel } from '../interfaces/form';
import { getDefaultValue } from './formMaker';
import { FormValidation } from '../interfaces';
import { PayloadErrorData } from 'ah-requests';

export function getChildModel(model: FormModel, name: string) {
  const fieldNames = name.split('.');
  let child: BaseFormModel = model;
  for (let i = 0; i < fieldNames.length; i++) {
    const found = (child as FormModel).$fields && (child as FormModel).$fields.find((f) => f.$name === fieldNames[i]);
    if (found) {
      child = found;
    } else {
      return undefined;
    }
  }
  return child;
}

export function resetForm(form: FormValidation<BaseFormModel>) {
  setState(form.$model, 'submitted', false);
  form.$reset();
}

export function submitForm(form: FormValidation<BaseFormModel>) {
  if (Array.isArray(form.$model)) {
    form.$model.forEach((m) => setState(m, 'submitted', true));
  } else {
    setState(form.$model, 'submitted', true);
  }
  form.$touch();
}

/**
 * Gets a state value, optionally defining a default value and a hierarchy strategy
 *
 * If useHierarchy is:
 *  - false => it will not use any parent values
 *  - true => it will use parent values, stopping at the closest parent with a set value
 *  - a reducer Function => it will go through the parent chain, and reduce the values using the Function.
 *    An example where a value is true if any item in the hierarchy has a value of true is (curr, parent) => curr || parent
 */
export function getState(
  model: BaseFormModel,
  key: string,
  defaultValue?: any,
  useHierarchy: boolean | ((curr: any, parent: any) => any) = false
) {
  let obj: BaseFormModel | undefined = model;
  let value = defaultValue;
  do {
    if (obj) {
      if (Object.prototype.hasOwnProperty.call(obj.$state, key)) {
        if (typeof useHierarchy === 'function') {
          value = useHierarchy(value, obj.$state[key]);
        } else {
          return obj.$state[key];
        }
      }
      obj = obj.$parent && obj.$parent();
    }
  } while (obj && useHierarchy);

  return value;
}

export function setState(model: BaseFormModel, key: string, value: any, setChildren = false) {
  Vue.set(model.$state, key, value);
  if (setChildren && model.$type === 'form') {
    if (model.$fields) {
      (model as FormModel).$fields.forEach((f) => setState(f, key, value, setChildren));
    } else if (model.$constructor && model.$value) {
      (model as RepeatModel).$value.forEach((f) => setState(f, key, value, setChildren));
    }
  }
}

export function batchSetState(
  model: FormModel,
  stateKey: string,
  childKeyValues: { [key: string]: any } | string[],
  commonValue?: any
) {
  const fieldKeys = Array.isArray(childKeyValues) ? childKeyValues : Object.keys(childKeyValues);

  fieldKeys.forEach((k) => {
    const child = getChildModel(model, k);
    if (child) {
      Vue.set(child.$state, stateKey, Array.isArray(childKeyValues) ? commonValue : childKeyValues[k]);
    }
  });
}

export function toggleState(model: BaseFormModel, key: string) {
  setState(model, key, !getState(model, key));
}

function getParent(baseForm: FormValidation<BaseFormModel> & { [key: string]: any }, path: string) {
  const pathParts = path.split('.');
  let form: FormValidation<BaseFormModel> & { [key: string]: any } = baseForm;
  for (let pathIndex = 1; pathIndex < pathParts.length - 1; pathIndex += 1) {
    form = form[pathParts[pathIndex]];
  }

  return form;
}

export function isValidUntil(baseForm: FormValidation<BaseFormModel> & { [key: string]: any }, path: string) {
  const form = getParent(baseForm, path);
  const model = form.$model;

  for (let i = 0; i < model.$fields.length; i += 1) {
    if (model.$fields[i].$path === path) {
      return !form[model.$fields[i].$name].$invalid;
    }
    if (form[model.$fields[i].$name].$invalid) {
      return false;
    }
  }
  return true;
}

export function isSelectable(baseForm: FormValidation<BaseFormModel> & { [key: string]: any }, path: string) {
  const form = getParent(baseForm, path);
  const model = form.$model as FormModel;
  const fieldIndex = model.$fields.findIndex((f) => f.$path === path);

  return fieldIndex === 0 || model.$fields[fieldIndex - 1].$state.state === 'completed';
}

/**
 * Returns a clean model from a FormModel
 * Potentially filtered by specific field names
 * Returned value is a deep clone of the form model, and safe to change without affecting the original
 */
export function toDataModel(obj: BaseFormModel, fieldNames?: string[]): any {
  if (Array.isArray(obj)) {
    return obj.map((o) => toDataModel(o, fieldNames));
  }

  if (obj && obj.$type) {
    return Object.keys(obj)
      .filter((key) => !key.startsWith('$') && !key.startsWith('_'))
      .filter((key) => !fieldNames || fieldNames.includes(key))
      .reduce((out, key) => {
        const field = (obj.$fields || []).find((f: BaseFormModel) => f.$name === key);

        if (field && field.$state.toDataModelFn) {
          field.$state.toDataModelFn(cloneDeep(obj[key]), out, obj);
        } else {
          out[key] = toDataModel(obj[key]);
        }

        return out;
      }, {} as any);
  }

  return cloneDeep(obj);
}

export function updateModel(
  model: FormModel,
  obj: { [key: string]: any },
  fieldNames?: string[],
  complete = false
): void {
  if (!obj) {
    return;
  }

  const fields = model.$fields.filter((f) => !fieldNames || fieldNames.includes(f.$name));

  if (complete) {
    Object.keys(obj)
      .filter((k) => !k.startsWith('$') && !k.startsWith('_') && !fields.find((f) => f.$name === k))
      .forEach((k) => {
        model[k] = obj[k];
      });
  }

  fields.forEach((field) => {
    const key = field.$name;
    const defaultValue = getDefaultValue(field);

    if (field.$state.fromDataModelFn) {
      field.$state.fromDataModelFn(obj, model);
    } else if (field.$type === 'form') {
      if (obj[key] !== undefined || complete) {
        if (field.$state.multiple) {
          model[key] = (obj[key] ?? defaultValue ?? []).map((o: { [key: string]: any }, index: number) => {
            const item = model[key][index] || (field as RepeatModel).$constructor();
            updateModel(item, o, undefined, complete);
            return item;
          });
        } else {
          updateModel(model[key], obj[key] ?? defaultValue, undefined, complete);
        }
      }
    } else if (
      (fieldNames && fieldNames.indexOf(field.$name) < -1) ||
      Object.prototype.hasOwnProperty.call(obj, key) ||
      complete
    ) {
      model[key] = obj[key] ?? defaultValue;
    }
  });
}

export function completeAllValid(form: FormValidation<BaseFormModel> & { [key: string]: any }) {
  const model = form.$model;

  for (let i = 0; i < model.$fields.length; i += 1) {
    if (!form[model.$fields[i].$name].$invalid) {
      setState(model.$fields[i], 'state', 'completed');
    } else {
      break;
    }
  }
  return true;
}

/**
 * Scroll the user to a given field.
 *
 * if multiple field with the same name are found, the page will scroll into the first
 * one or the one with `index`, if set.
 */
export function scrollToField(formModels: BaseFormModel | BaseFormModel[], index?: number) {
  if (!isArray(formModels)) formModels = [formModels];

  const elements: Element[] = [];
  formModels.forEach((val) => elements.push(...Array.from(document.getElementsByName(val.$name))));
  if (elements.length > 0) {
    scrollToElement(Array.from(elements), index);
  }
}

/**
 * Scroll the user to a given validation.
 *
 * if multiple validations with the same model name are found, the page will scroll into the first
 * one or the one with `index`, if set.
 */
export function scrollToValidation(
  validations: FormValidation<BaseFormModel> | FormValidation<BaseFormModel>[],
  index?: number
) {
  if (!isArray(validations)) validations = [validations];

  const elements: Element[] = [];
  validations.forEach((val) => elements.push(...Array.from(document.getElementsByName(val.$model.$name))));
  if (elements.length > 0) {
    scrollToElement(Array.from(elements), index);
  }
}

export function scrollToElement(el: Element | Element[], index?: number) {
  if (isArray(el)) {
    if (index && index <= el.length) {
      el = el[index];
    } else {
      el = el.reduce((a, b) => (a.getBoundingClientRect().y < b.getBoundingClientRect().y ? a : b));
    }
  }
  el.scrollIntoView({ block: 'center', behavior: 'smooth' });
}

/**
 *
 * Sets an error in any form field given a form model
 */
export function setErrorMessage(form: FormModel, fieldName: string, message: string, errorName = 'customError') {
  const field = getChildModel(form, fieldName);
  if (field) {
    setState(field, 'errors', [{ name: errorName, error: message }]);
  }
}

/**
 *
 * Populate form error based on api response errors,
 * this method presumes that the form fields match the ones on api request exactly
 */
export function setApiErrorMessages(apiErrors: PayloadErrorData, form: FormModel) {
  const fields = apiErrors.fields;
  if (fields) {
    Object.keys(fields).forEach((fieldName) => {
      const formField = getChildModel(form, fieldName);

      if (formField) {
        const errors = fields[fieldName]?.errors || [];
        setState(
          formField,
          'errors',
          errors.map((fieldError, index) => ({ name: `apiError - ${index}`, error: fieldError.message }))
        );
      }
    });
  }
}

export function generateUsername(firstName: string = '', lastName: string = ''): string {
  if (!firstName || !lastName) {
    return '';
  }

  const parts = [firstName, lastName]
    .filter(Boolean)
    .map((name) => name.trim())
    .filter((name) => name.length > 0);

  return parts.join('').toLowerCase().replace(/\s+/g, '');
}
