import { useAuthStore } from '@/app/store/authStore';
import { Client, PublicComplianceCase, OnboardingIndividualInfo } from 'ah-api-gateways';
import { WizardConfig } from 'ah-common-lib/src/models/progress';

export enum RegistrationStepKeys {
  CLIENT_COUNTRY_CHECK = 'client-country-check',
  PERSONAL_DETAILS = 'personal-details',
  ACCOUNT_VERIFICATION = 'account-verification',
  TRADING_DETAILS = 'trading-details',
  DOCUMENTATION = 'documentation',
  BANKING_PROVIDER = 'banking-provider',

  /**
   * Company client specific steps
   */
  COMPANY_DETAILS = 'company-details',
  SIGNATORY = 'signatory',
  ADDITIONAL_USERS = 'personal-details',
}

export function makeIndividualRegistrationSteps(): WizardConfig<RegistrationStepKeys> {
  return {
    steps: [
      {
        key: RegistrationStepKeys.CLIENT_COUNTRY_CHECK,
        title: 'Country of Residence',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.PERSONAL_DETAILS,
        title: 'Personal Details',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.ACCOUNT_VERIFICATION,
        title: 'Verification',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.TRADING_DETAILS,
        title: 'Trading Details',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.DOCUMENTATION,
        title: 'Documentation',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.BANKING_PROVIDER,
        title: '',
        state: 'to-do',
        hiddenFromNavigation: true,
      },
    ],
    active: -1,
  };
}

export function makeCompanyRegistrationSteps(): WizardConfig<RegistrationStepKeys> {
  return {
    steps: [
      {
        key: RegistrationStepKeys.CLIENT_COUNTRY_CHECK,
        title: 'Country of Incorporation',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.PERSONAL_DETAILS,
        title: 'Contact Details',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.ACCOUNT_VERIFICATION,
        title: 'Verification',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.COMPANY_DETAILS,
        title: 'Company Details',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.SIGNATORY,
        title: 'Authorised Signatory',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.TRADING_DETAILS,
        title: 'Trading Details',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.DOCUMENTATION,
        title: 'Documentation',
        state: 'to-do',
      },
      {
        key: RegistrationStepKeys.BANKING_PROVIDER,
        title: '',
        state: 'to-do',
        hiddenFromNavigation: true,
      },
    ],
    active: -1,
  };
}

export function setActiveStep(key: RegistrationStepKeys | number, flow: WizardConfig) {
  if (typeof key === 'number') {
    flow.active = key;
  } else {
    flow.active = flow.steps.findIndex((step) => step.key === key);
  }
}

export function getStepIndex(key: RegistrationStepKeys, flow: WizardConfig) {
  return flow.steps.findIndex((step) => step.key === key);
}

export function calculateCurrentApplicantionFlow(
  flow: WizardConfig<RegistrationStepKeys>,
  client?: Client,
  complianceCase?: PublicComplianceCase,
  individuals?: OnboardingIndividualInfo[]
): WizardConfig<RegistrationStepKeys> {
  const authStore = useAuthStore();
  const questionnaire = complianceCase?.questionnaire;

  flow.steps.forEach((step) => {
    step.state = 'to-do';

    const setValid = (condition = true) => {
      const index = getStepIndex(step.key, flow);
      if (index > -1 && condition) {
        // convert current and all previous steps to valid
        let i = 0;
        while (i <= index) {
          flow.steps[i].state = 'valid';
          i++;
        }
      }
    };

    switch (step.key) {
      /**
       * if logged in, the user already started the application and thus the account access details
       * are already in place
       */
      case RegistrationStepKeys.PERSONAL_DETAILS:
        return setValid(!!authStore.userData);

      /**
       * if the current client is logged in and have an user,
       * we know that he/she already verified her/his phone number and email address
       */
      case RegistrationStepKeys.ACCOUNT_VERIFICATION:
        return setValid(authStore.isUserVerified && !!authStore.userData?.individual?.client);

      /**
       * if the current client contains a country code in the address we assume the form as been filled in
       * already (since this field is mandatory for the rest of the form to be visible) and a filled in country code
       * means that the user already saved a valid instance of the company details step
       */
      case RegistrationStepKeys.COMPANY_DETAILS:
        return setValid(!!client?.companyDetails.address.countryCode);

      /**
       * it's hard to figure out if the user as already been on this step, since the user can just skip it (no field is mandatory).
       * Because of that we are checking if there's any proposed, if so we know that this field as already been filled in, otherwise
       * we still don't know if the field was completed and we are taking other steps into consideration to know if this step was already
       * completed, meaning:
       *  - proposed addition user => completed step
       *  - no proposed addition users => we don't know
       *  - next step is already filled in => completed step
       */
      case RegistrationStepKeys.SIGNATORY:
        const anyProposedUsers = individuals?.findIndex((ind) => !ind.applicant && !ind.owner) ?? -1;
        return setValid(anyProposedUsers > -1);

      /**
       * if the current client includes a spot interest or a forward interest (since at least one is mandatory)
       * we assume that the current step as already been filled in by the user.
       */
      case RegistrationStepKeys.TRADING_DETAILS:
        const includesTradingDetails = !!questionnaire?.fxSpotInterest || !!questionnaire?.fxForwardInterest;
        return setValid(includesTradingDetails);

      /**
       * we don't need to check for the DOCUMENTATION step, since this step being finished means that the
       * user already completed the onboarding proccess
       */
      case RegistrationStepKeys.DOCUMENTATION:
      default:
        break;
    }
  });

  flow = calculateCurrentApplicantionActiveStep(flow);
  return flow;
}

/**
 * Sets the active step as the first flow step on a 'to-do' state
 */
export function calculateCurrentApplicantionActiveStep(
  flow: WizardConfig<RegistrationStepKeys>
): WizardConfig<RegistrationStepKeys> {
  flow.active = Math.max(
    flow.steps.findIndex((step) => step.state === 'to-do'),
    0
  );
  return flow;
}
