<script setup lang="ts">
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { checkboxField } from 'ah-common-lib/src/form/models';
import ClientDocumentsUploader from '@/app/components/settings/client/ClientDocumentsUploader.vue';
import TermsAcceptanceForm from './TermsAcceptanceForm.vue';
import {
  ClientType,
  ClientFileCategories,
  UploadedFile,
  CompanyRegistrationModel,
  UnsubmittedUbo,
  ComplianceErrorCodes,
  CurrentAddressHistoryItem,
  AddressHistoryItem,
  EntityTypeAddress,
  getAddressHistoryError,
  checkAddressHistoryValidity,
  BaseIndividual,
  FeatureFlag,
} from 'ah-api-gateways';
import UBOForm from '../company/UBOForm.vue';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { set, computed, reactive, ref, watch, onMounted } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { cloneDeep } from 'lodash';
import { catchError, defaultIfEmpty, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import { HttpError } from 'ah-requests/http/httpService';
import { useToast } from 'ah-common-lib/src/toast';
import { useFeatureFlag } from 'ah-common-stores';
import { forkJoinWithCompletion } from 'ah-common-lib/src/helpers/rxjs';
import { isTempUUID } from 'ah-common-lib/src/helpers/uuid';

const props = defineProps<{ model: Partial<CompanyRegistrationModel> }>();

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const toast = useToast();

const showUboAddressHistoryErrors = ref(false);

const state = reactive<{
  uboUsers: UnsubmittedUbo[];
  files: UploadedFile[];
  uboValidation: FormValidation | null;
  termsValidation: FormValidation | null;
}>({
  uboUsers: [],
  files: [],
  uboValidation: null,
  termsValidation: null,
});

const emit = defineEmits<{
  (e: 'update:validation', value: FormValidation<CompanyRegistrationModel>): void;
  (e: 'proceed', value: void): void;
}>();

const uploadLaterFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'uploadLater',
    fieldType: 'form',
    fields: [
      checkboxField('laterUpload', 'I will upload missing documents later.', false, {
        required: false,
        errorMessages: {
          mustAccept: 'Must confirm this or upload your identification documents to continue',
        },
      }),
    ],
  }),
  validation: null,
});

function loadDocuments() {
  requestManager
    .sameOrCancelAndNew(
      'getClientDocuments',
      services.client.getDocuments(authStore.loggedInIdentity!.client!.id, { errors: { silent: true } })
    )
    .subscribe((response) => (state.files = response));
}

loadDocuments();

const isOwner = computed(() => {
  if (authStore.loggedInIdentity?.client?.type !== ClientType.COMPANY) {
    return true;
  }
  if (props.model.individuals) {
    const owner = props.model.individuals.find((i) => i.owner);
    if (owner) {
      return owner.id === authStore.loggedInIdentity.id;
    }
  }
  return !!authStore.loggedInIdentity?.owner;
});

const owner = computed(() => {
  return props.model.individuals?.find((i) => i.owner);
});

const secondaryOwner = computed(() => {
  return props.model.individuals?.find((i) => i.secondaryOwner);
});

const isCompany = computed(() => authStore.isCompanyClient);

const termsAndConditionsRequired = computed(() => !isCompany.value || isOwner.value);

const uploadingLater = computed(() => uploadLaterFM.form.laterUpload);

const { isActive: isOnboardingDocumentsActive } = useFeatureFlag({
  featureFlag: FeatureFlag.ONBOARDING_DOCUMENTS_ENABLED,
});

const fileCategories = computed(() => {
  if (isCompany.value) {
    const out = [
      ClientFileCategories.PROOF_OF_ADDRESS,
      ClientFileCategories.PHOTO_ID,
      ClientFileCategories.INCORPORATED_DOCUMENT,
      ClientFileCategories.FINANCIAL_STATEMENT,
      ClientFileCategories.SAMPLE_INVOICE,
    ];
    if (!state.uboUsers?.length) {
      out.push(ClientFileCategories.RESOLUTION_LETTER);
    }
    if (!!props.model.individuals?.find((i) => i.secondaryOwner)) {
      out.push(ClientFileCategories.SECONDARY_OWNER_PROOF_OF_ADDRESS, ClientFileCategories.SECONDARY_OWNER_PHOTO_ID);
    }
    return out;
  } else {
    if (isOnboardingDocumentsActive.value) {
      return [ClientFileCategories.PROOF_OF_FUNDS];
    } else {
      return [
        ClientFileCategories.PROOF_OF_ADDRESS,
        ClientFileCategories.PHOTO_ID,
        ClientFileCategories.PROOF_OF_FUNDS,
      ];
    }
  }
});

const allFilesUploaded = computed(() => {
  if (filteredFiles.value.length < fileCategories.value.length) {
    return false;
  }

  if (isCompany.value) {
    for (let i = 0; i < (state.uboUsers?.length ?? 0); i++) {
      const user = state.uboUsers![i];
      if (!user.readyToUpload || user.readyToUpload.filter((i) => !!i.file).length < 2) {
        return false;
      }
    }
  }
  return true;
});

const filteredFiles = computed(() => {
  return state.files.filter((file) => fileCategories.value.includes(file.category as ClientFileCategories));
});

const proofOfFundsUploaded = computed(() =>
  state.files.some((file) => file.category === ClientFileCategories.PROOF_OF_FUNDS)
);

const validUploadedFiles = computed(() => {
  if (isCompany.value) {
    return uploadingLater.value || allFilesUploaded.value || !isOnboardingDocumentsActive.value;
  } else {
    return proofOfFundsUploaded.value || uploadingLater.value;
  }
});

const areFormsInvalid = computed(() => {
  if (!isOwner.value) {
    return !validUploadedFiles.value;
  } else {
    return !validUploadedFiles.value || (termsAndConditionsRequired.value && state.termsValidation?.$invalid);
  }
});

const validation = computed(() => {
  return {
    $model: {},
    $invalid: areFormsInvalid.value || state.uboValidation?.$invalid,
    $dirty:
      state.uboValidation?.$anyDirty ||
      !!state.termsValidation?.$dirty ||
      state.files.length > 0 ||
      uploadingLater.value,
  } as FormValidation<CompanyRegistrationModel>;
});

const clientId = computed(() => authStore.loggedInIdentity!.client!.id);

const showUploadLaterCheckbox = computed(() => {
  if (isCompany.value) {
    return isOnboardingDocumentsActive.value && !allFilesUploaded.value;
  } else {
    return !proofOfFundsUploaded.value && (!isOnboardingDocumentsActive.value || !allFilesUploaded.value);
  }
});

function approveTermsRequest() {
  if (termsAndConditionsRequired.value) {
    // FIXME we skip any errors related to "Terms and conditions already accepted" as it is a known API issue
    return services.client
      .acceptTermsAndConditions(clientId.value, {
        options: {
          errors: {
            silent(error) {
              return error.response?.data.code === ComplianceErrorCodes.TERMS_AND_CONDITIONS_ALREADY_APPROVED;
            },
          },
        },
      })
      .pipe(
        catchError((error: HttpError) => {
          if (error.response?.data.code === ComplianceErrorCodes.TERMS_AND_CONDITIONS_ALREADY_APPROVED) {
            return of(null);
          }
          return throwError(error);
        })
      );
  } else {
    return of(null);
  }
}

function skipDocumentsRequest() {
  const shouldSkip =
    !isOnboardingDocumentsActive.value ||
    uploadingLater.value ||
    (!isCompany.value && filteredFiles.value.length === 0);

  return shouldSkip ? services.compliance.uploadLater(clientId.value) : of(undefined);
}

function checkUboAddressHistoryValidity() {
  const anyInvalid = state.uboUsers.some((ubo) => {
    if (ubo.currentAddress.countryCode !== 'GB') {
      return false;
    }
    const validityCheck = checkAddressHistoryValidity(ubo);
    return !validityCheck.age || !validityCheck.gaps;
  });
  return !anyInvalid;
}

function uboSubmissionRequest() {
  const requests: Observable<any>[] = [];

  props.model.uboUsers?.forEach((savedUbo) => {
    if (savedUbo.id && !isTempUUID(savedUbo.id) && !state.uboUsers.some((ubo) => ubo.id === savedUbo.id)) {
      requests.push(services.compliance.removeUboUser(clientId.value, savedUbo.id));
    }
  });

  state.uboUsers?.forEach((ubo) => {
    const { documents, readyToUpload, ...uboInfo } = cloneDeep(ubo);
    if (uboInfo.phoneNumber?.length === 0) delete uboInfo.phoneNumber;

    const validateHistoryRequest = services.compliance
      .validateAddressHistory({
        currentAddress: ubo.currentAddress,
        previousAddresses: ubo.previousAddresses,
      })
      .pipe(
        catchError((e: HttpError) => {
          set(ubo, 'addressHistoryApiError', e.response?.data);
          throw e;
        })
      );

    const uboUpdateRequest = (
      uboInfo.id && !isTempUUID(uboInfo.id)
        ? services.compliance.updateUboUser(clientId.value, uboInfo.id, uboInfo as BaseIndividual)
        : services.compliance.createUboUser(clientId.value, uboInfo as BaseIndividual)
    ).pipe(
      tap((response) => {
        Object.assign(ubo, response);
      }),
      catchError((e: HttpError) => {
        set(ubo, 'userCreationApiError', e.response?.data);
        throw e;
      })
    );

    requests.push(
      validateHistoryRequest.pipe(
        mergeMapTo(uboUpdateRequest),
        mergeMap((response) =>
          forkJoin([
            ...(readyToUpload ?? [])
              .filter((file) => !!file.file)
              .map((file) =>
                services.compliance.submitUboDocument(clientId.value, response.id!, file.category, file.file!)
              ),
            services.compliance.createAddressHistory({
              currentAddress: ubo.currentAddress,
              previousAddresses: ubo.previousAddresses,
              entityId: response.id!,
              type: EntityTypeAddress.UBO,
            }),
          ]).pipe(defaultIfEmpty([] as any[]))
        ),
        catchError((error: HttpError) => {
          const errorMessage = getAddressHistoryError(error);

          if (errorMessage) {
            toast.error(errorMessage);
          }

          return throwError(error);
        })
      )
    );
  });

  return requests;
}

function submit() {
  const validAddressHistories = checkUboAddressHistoryValidity();
  if (!validAddressHistories) {
    toast.error(
      'Please review UBO addresses. UK residents must provide addresses spanning back 3 years, with no gaps.'
    );
    showUboAddressHistoryErrors.value = true;
    return;
  }

  requestManager
    .currentOrNew(
      'submitRegistration',
      forkJoinWithCompletion(uboSubmissionRequest()).pipe(
        defaultIfEmpty([null]),
        mergeMap(() => skipDocumentsRequest()),
        mergeMap(() => approveTermsRequest())
      )
    )
    .subscribe(() => emit('proceed'));
}

onMounted(() => {
  if (isCompany.value) {
    initializeUboUsers();
  }
});

function initializeUboUsers() {
  if (props.model.uboUsers?.length) {
    state.uboUsers = cloneDeep(props.model.uboUsers);
  } else {
    const ubos: UnsubmittedUbo[] = [];
    if (props.model.individuals) {
      [owner.value, secondaryOwner.value].forEach((individual) => {
        if (individual) {
          ubos.push({
            firstName: individual.firstName,
            lastName: individual.lastName,
            title: individual.title,
            email: individual.email,
            phoneNumber: individual.phoneNumber,
            birthDate: individual.birthDate,
            documents: [],
            currentAddress: individual.currentAddress as CurrentAddressHistoryItem,
            previousAddresses: individual.previousAddresses as AddressHistoryItem[],
            ownershipPercentage: 25.0,
          });
        }
      });
    }
    state.uboUsers = ubos;
  }
}

watch(
  () => props.model,
  () => {
    if (isCompany.value) {
      initializeUboUsers();
    }
  },
  { immediate: true }
);

watch(
  allFilesUploaded,
  () => {
    setState(getChildModel(uploadLaterFM.form, 'laterUpload')!, 'hidden', allFilesUploaded.value);
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div x-test-name="registration-documents-form">
    <div>
      <h2>Documentation</h2>
      <template v-if="isCompany">
        <template v-if="isOnboardingDocumentsActive">
          <div class="card-block individual-documents row mb-3">
            <div class="col col-12">
              <h3 class="mt-3 col col-12">
                Verification of Authorised Signatory
                <template v-if="owner && secondaryOwner">- {{ owner.firstName }} {{ owner.lastName }}</template>
              </h3>
              <ClientDocumentsUploader
                :files.sync="state.files"
                :categories="[ClientFileCategories.PHOTO_ID, ClientFileCategories.PROOF_OF_ADDRESS]"
              />
            </div>
          </div>
          <div class="card-block individual-documents row mb-3" v-if="secondaryOwner">
            <div class="col col-12">
              <h3 class="mt-3 col col-12">
                Verification of Secondary Signatory - {{ secondaryOwner.firstName }} {{ secondaryOwner.lastName }}
              </h3>
              <ClientDocumentsUploader
                :files.sync="state.files"
                :categories="[
                  ClientFileCategories.SECONDARY_OWNER_PHOTO_ID,
                  ClientFileCategories.SECONDARY_OWNER_PROOF_OF_ADDRESS,
                ]"
              />
            </div>
          </div>
          <div class="card-block individual-documents row mb-3">
            <div class="col col-12">
              <h3 class="mt-3 col col-12">Company Documents</h3>
              <ClientDocumentsUploader
                :files.sync="state.files"
                :categories="[
                  ClientFileCategories.INCORPORATED_DOCUMENT,
                  ClientFileCategories.FINANCIAL_STATEMENT,
                  ClientFileCategories.SAMPLE_INVOICE,
                ]"
              />
            </div>
          </div>
        </template>
        <UBOForm
          class="my-3"
          :files.sync="state.files"
          :ubos.sync="state.uboUsers"
          :validation.sync="state.uboValidation"
          :showUboAddressHistoryErrors="showUboAddressHistoryErrors"
        />
      </template>
      <template v-else>
        <div class="card-block individual-documents row mb-3">
          <div class="col col-12">
            <h3 class="mt-3 col col-12">Verification of Authorised Signatory</h3>
            <ClientDocumentsUploader
              :files.sync="state.files"
              :categories="[
                ClientFileCategories.PHOTO_ID,
                ClientFileCategories.PROOF_OF_ADDRESS,
                ClientFileCategories.PROOF_OF_FUNDS,
              ]"
            />
          </div>
        </div>
      </template>
      <div v-if="showUploadLaterCheckbox">
        <ValidatedForm class="my-3" :fm="uploadLaterFM.form" :validation.sync="uploadLaterFM.validation" />
      </div>
    </div>
    <div v-if="termsAndConditionsRequired">
      <h2>Terms and Conditions of Service</h2>
      <TermsAcceptanceForm :validation.sync="state.termsValidation" />
    </div>
    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="validation.$invalid"> Continue </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}
</style>
