import { APIGatewayURls, AppConfig, baseConfig } from "./baseConfig";

export default {
  ...baseConfig,
  ...APIGatewayURls('https://sit2.api.alt21.com/v3.2/'),
  staticOTP: true,
  showDevTools: true,
  allowMockedFunding: true,
  altReferer: 'https://sit2.x-hedge.alt21.com/',
} as AppConfig;

