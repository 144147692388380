<script setup lang="ts">
import UserLogin from '@/app/components/session/UserLogin.vue';
import { BrandingImageType } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { useTheme } from 'ah-theme';
import { useRouter } from 'vue-router/composables';
import { computed, ref } from 'vue';
import { BModal } from 'bootstrap-vue';
import VRow from 'ah-common-lib/src/common/components/VRow.vue';
import VCol from 'ah-common-lib/src/common/components/VCol.vue';
import AccountCircleIcon from 'ah-common-lib/src/icons/components/AccountCircleIcon.vue';
import LockOpenIcon from 'ah-common-lib/src/icons/components/LockOpenIcon.vue';

const props = defineProps({
  redirectTo: { type: String, default: '' },
  redirectToQuery: { type: String, default: '' },
});

const authStore = useAuthStore();

const theme = useTheme();

const router = useRouter();

const userLogin = ref<InstanceType<typeof UserLogin>>();

const currentStage = ref<String>('');

const showModal = ref(false);

const openCannotLoginModal = () => {
  showModal.value = true;
};

const splashLogoLink = computed(() => {
  let out = theme.val?.logos.find((l) => l.type === BrandingImageType.SPLASH_IMAGE)?.link;
  return out;
});

function resendCode() {
  userLogin.value?.refreshOtpCode();
}

function onLoginSuccess() {
  if (authStore.isUnverifiedSelfRegister) {
    router.push('/register');
    return;
  }
  let path = authStore.isClientUser ? '/dashboard' : '/admin';
  let query = {};
  try {
    query = JSON.parse(props.redirectToQuery);
  } catch (e) {}
  if (props.redirectTo) {
    path = props.redirectTo;
  }
  router.push({ path, query });
}

/**
 * Clearing session for the use case where a logged in user goes back
 * to the login screen while with a registration session
 */
if (authStore.hasRegistrationSession) {
  authStore.logout();
}
</script>

<template>
  <div class="login-container">
    <SimpleCenteredPage narrow id="login-view">
      <img class="company-logo-large" :src="splashLogoLink" alt="" v-if="splashLogoLink && currentStage === 'login'" />
      <h1 class="mb-5" v-else-if="currentStage === 'otp'">{{ $t('login.twoStepAuth') }}</h1>
      <UserLogin
        id="user-login"
        ref="userLogin"
        @login-success="onLoginSuccess"
        @stage-change="currentStage = $event"
      />
      <template #footer>
        <div v-if="currentStage === 'login'">
          <div class="reset-user-credentials">
            <div class="mb-2 cannot-login" @click="openCannotLoginModal">{{ $t('login.cannotLogin') }}</div>
            <div>
              {{ $t('login.dontHaveAccount') }} <RouterLink to="/register"> {{ $t('login.register') }} </RouterLink>
            </div>
          </div>
        </div>
        <div v-else-if="currentStage === 'otp'">
          {{ $t('login.didntReceive') }}
          <a href="#" @click.prevent="resendCode">{{ $t('login.resendCode') }}</a>
        </div>
      </template>
    </SimpleCenteredPage>
    <BModal modal-class="cannot-login-modal" v-model="showModal" title="Can't log in?" centered hide-footer>
      <VRow>
        <VCol cols="6">
          <div class="forgot-username-link" @click="router.push('/recoverUsername')">
            <AccountCircleIcon class="mb-4" />
            <div>
              <h5 class="mb-1">Forgot Username?</h5>
              <p>If you have forgotten your username, you can request a reminder to be sent to your linked email.</p>
            </div>
          </div>
        </VCol>
        <VCol cols="6">
          <div class="forgot-password-link" @click="router.push('/resetPassword')">
            <LockOpenIcon class="mb-4" />
            <div>
              <h5 class="mb-1">Forgot Password?</h5>
              <p>If you have forgotten your password, you can reset it here.</p>
            </div>
          </div>
        </VCol>
      </VRow>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 43rem;

  .modal-content {
    padding: 15px;

    .modal-header {
      .modal-title {
        font-size: 1.375em;
        font-weight: $font-weight-semibold;
        font-family: $font-family-inter;
      }
    }
  }
}

.login-container {
  position: relative;
  height: 100%;
}

.company-logo-large {
  line-height: 200px;
  width: 100%;
  text-align: center;
  margin-bottom: $padded-space * 5;
}

.reset-user-credentials {
  font-size: 1em;
  font-weight: $font-weight-regular;
  width: fit-content;
  margin: 0 auto;

  .cannot-login {
    @include themedTextColor($color-primary);
    font-weight: $font-weight-semibold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.forgot-username-link,
.forgot-password-link {
  padding: 20px;
  border-radius: 3px;
  border: 1px solid getColor($color-primary);
  background-color: rgba($common-color-light-cyan, 0.1);
  font-family: $font-family-inter;
  height: 180px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba($common-color-light-cyan, 0.5);
  }

  h5 {
    font-size: 1em;
    font-weight: $font-weight-semibold;
    color: $xo-color-text-black;
  }

  p {
    font-size: 0.875em;
    font-weight: $font-weight-regular;
  }

  svg {
    height: 30px;
    width: 30px;
  }
}
</style>
