import { render, staticRenderFns } from "./ForgottenPasswordView.vue?vue&type=template&id=ef3239a8&scoped=true"
import script from "./ForgottenPasswordView.vue?vue&type=script&setup=true&lang=ts"
export * from "./ForgottenPasswordView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ForgottenPasswordView.vue?vue&type=style&index=0&id=ef3239a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3239a8",
  null
  
)

export default component.exports