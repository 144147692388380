<template>
  <div class="phone-number-change">
    <div class="field-title mb-1">Telephone number</div>
    <div class="phone">{{ getPhoneNumber(currentPhoneNumber) }}</div>
    <div class="link">
      <a class="text-secondary change-link text-small" @click="openChangeModal">Change phone number</a>
    </div>
    <div class="text-small pending-number" v-if="newPhoneNumber && !pendingVerification">
      <div class="icon">
        <IconDangerSign />
      </div>
      <div class="text">You have not verified your new telephone number<br />{{ getPhoneNumber(newPhoneNumber) }}.</div>
      <div class="link">
        <a class="text-secondary change-link" @click="openVerificationModal">Verify phone number</a>
      </div>
    </div>
    <BModal
      modal-class="change-modal"
      title="Change telephone number"
      ref="changeModal"
      hide-footer
      @hidden="onChangeModalHidden"
    >
      <div class="mb-3">
        <div class="field-title mb-1">Telephone number</div>
        <div class="phone">{{ getPhoneNumber(currentPhoneNumber) }}</div>
      </div>
      <ValidatedForm :fm="changeContactsForm" :validation.sync="phoneValidation" class="mb-3 phone-form" />
      <OtpRequestForm
        ref="changeOtpRequest"
        title="For security reasons, before confirming your new phone number we must verify your request."
        class="mb-5"
        :otp.sync="otp"
        :staticOTP="staticOTP"
        :otpPhone="currentPhoneLastDigits"
        :refreshOtpRequest="refreshOtpRequest"
        :forbidOtpRequest="isNewPhoneInvalid"
        :validation.sync="otpValidation"
        v-if="tfaEnabled"
      />
      <p class="text-center">
        <VButton @click="closeChangeModal" class="btn-stroked mr-3">Cancel</VButton>
        <VButton :loading="requestManager.anyPending" :disabled="anyInvalid" @click="changePhone">Confirm</VButton>
      </p>
    </BModal>
    <BModal
      modal-class="verification-modal"
      title=""
      ref="verificationModal"
      hide-footer
      @hidden="onVerificationModalHidden"
    >
      <div class="mb-3">
        <OtpRequestForm
          ref="validateOtpRequest"
          title="Please confirm your new phone number"
          :otp.sync="otp"
          :staticOTP="staticOTP"
          autoRequest
          :otpPhone="newPhoneLastDigits"
          :refreshOtpRequest="refreshNewPhoneOtpRequest"
          :validation.sync="otpValidation"
        />
      </div>
      <p class="text-center">
        <VButton @click="closeVerificationModal" class="btn-stroked mr-3">Cancel</VButton>
        <VButton :loading="requestManager.anyPending" :disabled="!otp" @click="confirmPhone">Confirm</VButton>
      </p>
    </BModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import OtpRequestForm from 'ah-common-lib/src/otp/OtpRequestForm.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { phoneField } from 'ah-common-lib/src/form/models';
import { helpers } from '@vuelidate/validators';
import config from '@/config';
import { SecurityErrorCodes } from 'ah-api-gateways';
import { flatMap, mergeMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { optional, checkParam, requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { FormModel } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';

/**
 * Phone number change component
 * This component will:
 * - Display the current telephone number, as defined in the logged in user
 * - Show a warning if the user has changed but not validated a new phone number
 */
@Component({
  components: {
    OtpRequestForm,
  },
})
export default class AccountPhoneNumberChange extends Mixins(WithRequestManager) {
  $refs!: {
    changeModal: BModal;
    verificationModal: BModal;
    changeOtpRequest: any; // InstanceType<typeof OtpRequestForm>;
    validateOtpRequest: any; // InstanceType<typeof OtpRequestForm>;
  };

  requestManagerConfig = {
    exposeToParent: false,
  };

  private otp = '';

  /**
   * Flag to temporarily hide the pending phone number warning
   * (when flow happens correctly, and user is sent from the change to the verification modal)
   */
  private pendingVerification = false;

  phoneValidation: FormValidation | null = null;

  otpValidation: FormValidation | null = null;

  private changeContactsForm: FormModel | null = null;

  getPhoneNumber = getPhoneNumber;

  created() {
    this.changeContactsForm = makeFormModel({
      name: 'changePhoneForm',
      fieldType: 'form',
      fields: [
        phoneField(
          'phoneNumber',
          'New telephone number',
          {
            errorMessages: { differentFrom: 'Cannot be the same as the current phone number' },
            required: true,
          },
          {
            differentFrom: helpers.withParams({ type: 'differentFrom' }, (val: any) => val !== this.currentPhoneNumber),
            phone: optional(checkParam('phoneNumber', 'valid')),
            required: requiredIfStateValue('phoneNumber'),
          }
        ),
      ],
    });
  }

  get authStore() {
    return useAuthStore();
  }

  get anyInvalid() {
    if (!this.otpValidation || !this.phoneValidation) {
      return true;
    }

    return this.otpValidation?.$invalid !== false || this.phoneValidation?.$invalid !== false;
  }

  get isNewPhoneInvalid() {
    if (!this.phoneValidation) {
      return true;
    }

    return this.phoneValidation?.$invalid !== false;
  }

  get currentPhoneNumber() {
    return this.authStore.loggedInUser?.individual?.phoneNumber ?? '';
  }

  get currentPhoneLastDigits() {
    return this.currentPhoneNumber.substring(Math.max(0, this.currentPhoneNumber.length - 3));
  }

  get newPhoneNumber() {
    return this.authStore.loggedInUser?.secondaryPhoneNumber ?? '';
  }

  get newPhoneLastDigits() {
    return this.newPhoneNumber.substring(Math.max(0, this.newPhoneNumber.length - 3));
  }

  get staticOTP() {
    return config.staticOTP;
  }

  get tfaEnabled() {
    return !!this.authStore.userData?.tfaEnabled;
  }

  changePhone() {
    let request: Observable<void> = this.tfaEnabled
      ? this.$services.account.changeContacts(
          {
            phoneNumber: this.changeContactsForm!.phoneNumber,
          },
          this.otp,
          {
            errors: {
              silent: true,
            },
          }
        )
      : this.$services.account.changeContacts(
          {
            phoneNumber: this.changeContactsForm!.phoneNumber,
          },
          undefined,
          {
            errors: {
              silent: true,
            },
          }
        );

    this.requestManager
      .sameOrCancelAndNew('changePhone', request.pipe(mergeMap(() => this.$services.auth.getSession())))
      .subscribe(
        (session) => {
          this.authStore.setTokenUser(session);
          this.closeChangeModal();
          if (session.secondaryPhoneNumber) {
            this.pendingVerification = true;
            this.openVerificationModal();
          } else {
            this.$toast.success('New phone number verified');
          }
        },
        (e) => {
          if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
            this.$refs.changeOtpRequest?.setOtpError(
              e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
            );
          }
        }
      );
  }

  confirmPhone() {
    this.requestManager
      .sameOrCancelAndNew(
        'changePhone',
        this.$services.account
          .verifyOtpForNumberChange(this.otp, {
            errors: {
              silent: true,
            },
          })
          .pipe(flatMap(() => from(this.authStore.refreshSession())))
      )
      .subscribe(
        () => {
          this.closeVerificationModal();
          this.$toast.success('New phone number verified');
        },
        (e) => {
          if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
            this.$refs.validateOtpRequest?.setOtpError(
              e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
            );
          }
        }
      );
  }

  refreshOtpRequest() {
    return this.$services.auth.refreshOtp();
  }

  refreshNewPhoneOtpRequest() {
    return this.$services.account.refreshOtpForNumberChange();
  }

  closeChangeModal() {
    this.$refs.changeModal.hide();
  }

  onChangeModalHidden() {
    this.otpValidation = null;
  }

  onVerificationModalHidden() {
    this.pendingVerification = false;
    this.otpValidation = null;
  }

  openChangeModal() {
    this.otp = '';
    this.$refs.changeModal.show();
  }

  closeVerificationModal() {
    this.$refs.verificationModal.hide();
  }

  openVerificationModal() {
    this.otp = '';
    this.$refs.verificationModal.show();
  }
}
</script>

<style lang="scss" scoped>
.change-link {
  text-decoration: underline;
}

.pending-number {
  margin-top: 0.3em;
  position: relative;

  .text,
  .icon {
    color: getColor($color-danger);
  }

  .text,
  .change-link {
    padding-left: 2em;
  }

  .icon {
    position: absolute;
    left: 0;
    top: -0.15em;
  }
}
</style>
