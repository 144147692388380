<script setup lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { emailField, passwordField } from 'ah-common-lib/src/form/models';
import { required } from '@vuelidate/validators';
import { sameAsField } from 'ah-common-lib/src/form/validators';
import { computed, reactive, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';
import { useRouter } from 'vue-router/composables';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';

const props = defineProps({ token: { type: String, required: true } });

const resetPasswordFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'resetPasswordForm',
    title: 'Reset Password',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email', { autocomplete: 'username', hidden: true, required: false }),
      passwordField('password', 'Password', true, { allowShowPassword: true }),
      passwordField(
        'passwordConfirm',
        'Confirm new password',
        false,
        {
          autocomplete: 'new-password',
        },
        {
          required,
          passwordMatch: sameAsField('password'),
        }
      ),
    ],
  }),
  validation: null,
});

const router = useRouter();

const toast = useToast();

const email = ref<string>('');

const requestManager = useRequestManager().manager;

const services = getServices();

function getEmailForPasswordResetToken() {
  requestManager
    .sameOrCancelAndNew(
      'getEmailForPasswordResetToken',
      services.account.getEmailForPasswordResetToken(props.token!),
      props.token
    )
    .subscribe(
      (response) => {
        email.value = response.email;
        resetPasswordFormDef.form.email = email.value;
      },
      () => {
        toast.error('Error with token provided.');
        router.replace('/');
      }
    );
}

getEmailForPasswordResetToken();

function submit() {
  const payload = {
    email: email.value,
    token: props.token!,
    newPassword: resetPasswordFormDef.form.password,
  };

  requestManager
    .sameOrCancelAndNew('confirmResetPassword', services.account.confirmResetPassword(payload), payload)
    .subscribe(() => {
      toast.success('Password reset successful.');
      router.push('/login');
    });
}

const loading = computed(() => requestManager.requestStates.confirmResetPassword === 'pending');
</script>

<template>
  <SimpleCenteredPage narrow id="reset-password-view">
    <div class="main-area narrow">
      <h1 class="mb-5">Reset password</h1>
      <p class="mb-m">Hi, {{ email }}. Please set up a new password to your {{ $theme.val.name }} account.</p>
      <ValidatedForm :fm="resetPasswordFormDef.form" :validation.sync="resetPasswordFormDef.validation" />
      <ul class="microcopy">
        <li>Minimum 8 characters</li>
        <li>Must include at least 1 number</li>
        <li>Must include at least 1 special character</li>
        <li>Must include at least 1 uppercase letter</li>
      </ul>
      <div class="form-actions">
        <VButton
          :disabled="resetPasswordFormDef.validation?.$invalid"
          :loading="loading"
          @click="submit"
          class="btn-success"
        >
          Reset my password
        </VButton>
      </div>
    </div>
  </SimpleCenteredPage>
</template>

<style lang="scss" scoped>
ul {
  list-style: disc;
  padding-left: math.div($padded-space, 2);
}
</style>
